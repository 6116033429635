// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-antraege-js": () => import("./../../../src/pages/antraege.js" /* webpackChunkName: "component---src-pages-antraege-js" */),
  "component---src-pages-freunde-danke-js": () => import("./../../../src/pages/freunde/danke.js" /* webpackChunkName: "component---src-pages-freunde-danke-js" */),
  "component---src-pages-freunde-freund-werden-js": () => import("./../../../src/pages/freunde/freund-werden.js" /* webpackChunkName: "component---src-pages-freunde-freund-werden-js" */),
  "component---src-pages-freunde-js": () => import("./../../../src/pages/freunde.js" /* webpackChunkName: "component---src-pages-freunde-js" */),
  "component---src-pages-freunde-mehr-js": () => import("./../../../src/pages/freunde/mehr.js" /* webpackChunkName: "component---src-pages-freunde-mehr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-danke-js": () => import("./../../../src/pages/kontakt/danke.js" /* webpackChunkName: "component---src-pages-kontakt-danke-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-spende-abbrechen-js": () => import("./../../../src/pages/spende/abbrechen.js" /* webpackChunkName: "component---src-pages-spende-abbrechen-js" */),
  "component---src-pages-spende-danke-js": () => import("./../../../src/pages/spende/danke.js" /* webpackChunkName: "component---src-pages-spende-danke-js" */),
  "component---src-pages-spendenkonto-js": () => import("./../../../src/pages/spendenkonto.js" /* webpackChunkName: "component---src-pages-spendenkonto-js" */),
  "component---src-pages-sponsoren-js": () => import("./../../../src/pages/sponsoren.js" /* webpackChunkName: "component---src-pages-sponsoren-js" */),
  "component---src-pages-stiftung-anlagerichtlinien-js": () => import("./../../../src/pages/stiftung/anlagerichtlinien.js" /* webpackChunkName: "component---src-pages-stiftung-anlagerichtlinien-js" */),
  "component---src-pages-stiftung-grundsaetze-js": () => import("./../../../src/pages/stiftung/grundsaetze.js" /* webpackChunkName: "component---src-pages-stiftung-grundsaetze-js" */),
  "component---src-pages-stiftung-js": () => import("./../../../src/pages/stiftung.js" /* webpackChunkName: "component---src-pages-stiftung-js" */),
  "component---src-pages-stiftung-principles-js": () => import("./../../../src/pages/stiftung/principles.js" /* webpackChunkName: "component---src-pages-stiftung-principles-js" */),
  "component---src-pages-stiftung-transparenz-js": () => import("./../../../src/pages/stiftung/transparenz.js" /* webpackChunkName: "component---src-pages-stiftung-transparenz-js" */),
  "component---src-pages-ueber-uns-beirat-js": () => import("./../../../src/pages/ueber-uns/beirat.js" /* webpackChunkName: "component---src-pages-ueber-uns-beirat-js" */),
  "component---src-pages-ueber-uns-geschaeftsstelle-js": () => import("./../../../src/pages/ueber-uns/geschaeftsstelle.js" /* webpackChunkName: "component---src-pages-ueber-uns-geschaeftsstelle-js" */),
  "component---src-pages-ueber-uns-grusswort-apd-js": () => import("./../../../src/pages/ueber-uns/grusswort-apd.js" /* webpackChunkName: "component---src-pages-ueber-uns-grusswort-apd-js" */),
  "component---src-pages-ueber-uns-grusswort-bvdd-js": () => import("./../../../src/pages/ueber-uns/grusswort-bvdd.js" /* webpackChunkName: "component---src-pages-ueber-uns-grusswort-bvdd-js" */),
  "component---src-pages-ueber-uns-grusswort-ddg-js": () => import("./../../../src/pages/ueber-uns/grusswort-ddg.js" /* webpackChunkName: "component---src-pages-ueber-uns-grusswort-ddg-js" */),
  "component---src-pages-ueber-uns-grusswort-nipd-js": () => import("./../../../src/pages/ueber-uns/grusswort-nipd.js" /* webpackChunkName: "component---src-pages-ueber-uns-grusswort-nipd-js" */),
  "component---src-pages-ueber-uns-grusswort-schirmherr-js": () => import("./../../../src/pages/ueber-uns/grusswort-schirmherr.js" /* webpackChunkName: "component---src-pages-ueber-uns-grusswort-schirmherr-js" */),
  "component---src-pages-ueber-uns-grusswort-vorstand-js": () => import("./../../../src/pages/ueber-uns/grusswort-vorstand.js" /* webpackChunkName: "component---src-pages-ueber-uns-grusswort-vorstand-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../../../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-pages-ueber-uns-komitee-js": () => import("./../../../src/pages/ueber-uns/komitee.js" /* webpackChunkName: "component---src-pages-ueber-uns-komitee-js" */),
  "component---src-pages-ueber-uns-kuratorium-js": () => import("./../../../src/pages/ueber-uns/kuratorium.js" /* webpackChunkName: "component---src-pages-ueber-uns-kuratorium-js" */),
  "component---src-pages-ueber-uns-schirmherr-js": () => import("./../../../src/pages/ueber-uns/schirmherr.js" /* webpackChunkName: "component---src-pages-ueber-uns-schirmherr-js" */),
  "component---src-pages-ueber-uns-vorstand-js": () => import("./../../../src/pages/ueber-uns/vorstand.js" /* webpackChunkName: "component---src-pages-ueber-uns-vorstand-js" */),
  "component---src-pages-unterstuetzen-js": () => import("./../../../src/pages/unterstuetzen.js" /* webpackChunkName: "component---src-pages-unterstuetzen-js" */),
  "component---src-pages-video-js": () => import("./../../../src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */),
  "component---src-pages-vorschau-js": () => import("./../../../src/pages/vorschau.js" /* webpackChunkName: "component---src-pages-vorschau-js" */),
  "component---src-pages-was-wir-tun-besonderhaut-js": () => import("./../../../src/pages/was-wir-tun/besonderhaut.js" /* webpackChunkName: "component---src-pages-was-wir-tun-besonderhaut-js" */),
  "component---src-pages-was-wir-tun-foerderprogramm-js": () => import("./../../../src/pages/was-wir-tun/foerderprogramm.js" /* webpackChunkName: "component---src-pages-was-wir-tun-foerderprogramm-js" */),
  "component---src-pages-was-wir-tun-js": () => import("./../../../src/pages/was-wir-tun.js" /* webpackChunkName: "component---src-pages-was-wir-tun-js" */),
  "component---src-templates-text-page-templ-js": () => import("./../../../src/templates/TextPageTempl.js" /* webpackChunkName: "component---src-templates-text-page-templ-js" */)
}

