require("./src/container/layout.css");

exports.onServiceWorkerUpdateReady = () => {
    const answer = window.confirm(
        `Es wurden Elemente auf der Webseite erneuert.` +
        `Möchten Sie die Webseite auf den neuesten Stand setzen?`
    );

    if (answer === true) {
        window.location.reload()
    }
};
